// extracted by mini-css-extract-plugin
export var arrow = "UserOrders__arrow__MJTLd";
export var button = "UserOrders__button__qJmQc";
export var cancelled = "UserOrders__cancelled__d87pM";
export var column = "UserOrders__column__ZISMb";
export var container = "UserOrders__container__r3XuB";
export var contentWrapper = "UserOrders__contentWrapper__YP217";
export var finalized = "UserOrders__finalized__ZHEpz";
export var icon = "UserOrders__icon__f2Fcu";
export var inProgress = "UserOrders__inProgress__aOlJI";
export var logo = "UserOrders__logo__bYChA";
export var noRecordCard = "UserOrders__noRecordCard__d2CAA";
export var order = "UserOrders__order__LlPwE";
export var orderTable = "UserOrders__orderTable__t364m";
export var paginationBar = "UserOrders__paginationBar__yckvU";
export var paginationInfo = "UserOrders__paginationInfo__QcznB";
export var row = "UserOrders__row__fVmZZ";
export var subTitle = "UserOrders__subTitle__uCpmt";
export var tag = "UserOrders__tag__AJQ_k";
export var tdTitle = "UserOrders__tdTitle__KkCeu";
export var title = "UserOrders__title__MMN2A";
export var titleMain = "UserOrders__titleMain__vFI0X";
export var userPanel = "UserOrders__userPanel__a8Yj9";
export var userPanelBody = "UserOrders__userPanelBody__DU__z";
export var userPanelHeader = "UserOrders__userPanelHeader__LKvGE";
export var userPanelItem = "UserOrders__userPanelItem__bVEsq";